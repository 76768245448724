<template>
  <div class="p-col-12 top-bar">
      <h3 class="font-bold text-3xl text-left" v-if="title && bold">
        {{ title }}
      </h3>
      <h4 v-else-if="title && !bold">{{ title }}</h4>
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
        v-if="breadcrumbItems"
      />
      <div class="p-mb-4" v-if="button">
      <Button
        :label="i18n.$t(`${button.title}`)"
        v-if="button && can(permission)"
        @click="$router.push({ name: button.link })"
      />
    </div>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    breadcrumbHome: {
      type: Object,
      default: {
        icon: 'pi pi-home',
        to: '/'
      }
    },
    breadcrumbItems: {
      type: Object,
      default: {}
    },
    title: {
      type: String
    },
    button: {
      type: Object
    },
    bold: {
      type: Boolean,
    },
    permission: {
      type: String,
      default: 'none'
    }
  },
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 0;
}
.p-button {
  width: auto;
}
.top-bar {
  width: 100%;
}
</style>
import store from '@/store';
import {
  SET_UI_NOTIFICATION,
  GET_USERDATA,
} from '@/store/types';
import { NOTIFICATION_TYPE } from '@/store/constant';

import axios from '@/plugins/axios';
import router from '../router';

export class UserDataInfo {
  ownerId() {
    return store.getters[GET_USERDATA].id
  }
  organisationId() {
    return store.getters[GET_USERDATA].organisation_id
  }
}
export class Notifications {
  async setNotifications(type = 'success', text) {
    if (type == 'close') {
      await store.commit(SET_UI_NOTIFICATION, {
        showNotification: false,
        notificationType: NOTIFICATION_TYPE.success,
        notificationText: ''
      });
    } else {
      await store.commit(SET_UI_NOTIFICATION, {
        showNotification: true,
        notificationType: NOTIFICATION_TYPE[type],
        notificationText: text,
      });
    }

  }
}


const userData = new UserDataInfo();
const n = new Notifications();

export class API {
  async delete(name, id, fetch, fixed = 'owner', type) {
    n.setNotifications('close');
    const userId = fixed == 'owner' ? userData.ownerId() : userData.organisationId();
    try {
      await axios.delete(`${name}/${id}`);

      const fetchValue = !type ? userId : {
        userId: userId,
        type: type
      };
      await store.dispatch(fetch, fetchValue);

      n.setNotifications('success', `${name} deleted successfully`);
    } catch (error) {
      console.log('[error]', error);
      n.setNotifications('error', `${name} not delete`);
    }
  }
  async update(name, notification, id, payload, route) {
    n.setNotifications('close');
    try {
      await axios.put(`/${name}/${id}`, payload);
      n.setNotifications('success', `${notification} updated successfully`);
      if(route) {
        setTimeout(() => {
          router.push(route)
        }, 750)
      }
    } catch (error) {
      console.log('[error]', error);
      n.setNotifications('error', `${notification} not updated`);
    }
  }
}


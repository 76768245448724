<template>
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card">
        <TopBar :breadcrumbItems="breadcrumbItems" :button="button" permission="v1.service.create"/>

        <DataTable
          :value="services"
          :paginator="true"
          class="p-datatable-customers"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:selection="selected"
          :filters="filters"
        >
          <template #header>
            <div class="table-header">
              {{ i18n.$t('List of Services') }}
            </div>
          </template>
          <template #empty> {{ i18n.$t('No services found') }} </template>
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column field="name" header="Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="category_name" header="Category" :sortable="false">
            <template #body="slotProps">
              <span
                style="margin-left: 0.5em; vertical-align: middle"
                class="image-text"
                >{{ slotProps.data.category.name }}</span
              >
            </template>
          </Column>
          <Column
            headerStyle="text-align: center"
            bodyStyle="text-align: center; overflow: visible; display: flex;"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info"
                @click="view(slotProps.data._id)"
                v-if="can('v1.service.view')"
              />
              <Button
                icon="pi pi-ellipsis-h"
                class="p-button-rounded p-button-info p-mr-1 p-ml-1"
                @click="update(slotProps.data._id)"
                v-if="can('v1.service.update')"
              />
              <ButtonConfirm @accept="onDelete(slotProps.data._id)" v-if="can('v1.service.delete')">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useI18n from '@/plugins/useI18n';
import { API } from '@/plugins/api';
import TopBar from '@/components/Utilities/TopBarBreadCrumb.vue';
import { FETCH_ALL_SERVICES, GET_SERVICES } from '@/store/types';

export default {
  components: {
    TopBar
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const services = computed(() => store.getters[GET_SERVICES]);
    const filters = ref({});
    const selected = ref([]);

    const button = {
      title: 'Add Service',
      link: 'service-add'
    };

    const breadcrumbItems = [{ label: 'Services', to: '/service/list' }];

    store.dispatch(FETCH_ALL_SERVICES);

    function update(serviceId) {
      router.push({
        path: `/service/edit`,
        query: { service: serviceId }
      });
    }

    function view(serviceId) {
      router.push({
        path: `/service/view`,
        query: { service: serviceId }
      });
    }
    const api = new API();
    function onDelete(id) {
      api.delete('service', id, FETCH_ALL_SERVICES);
    }
    return {
      i18n,
      filters,
      selected,
      services,
      update,
      view,
      onDelete,
      breadcrumbItems,
      button
    };
  }
};
</script>

<style lang="scss" scoped>
.p-datatable .p-column-filter {
  display: none;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.add-service {
  width: 150px;
  justify-content: center;
}
</style>